import React, { PureComponent, Fragment } from 'react';
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import history from '../../history';
import { logout } from '../../Store/actions/loginActions';
import validateLogin from '../../Validations/Login';
import validateSignup from '../../Validations/Registration';
import { userLoginRequest } from '../../Store/actions/loginActions';
import { userSignupRequest } from '../../Store/actions/signupActions';
import AlertWrapper from '../Common/AlertWrapper';
import AlertWrapperSuccess from '../Common/AlertWrapperSuccess';
import AlertWrapperWarning from '../Common/AlertWrapperWarning';
import "../../Assets/css/captcha.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { ECOM_URL, AJAX_REQUEST, CUSTOMER_URL, REFER_URL, REMOVE_STORAGE, USER, GET_COOKIE, GET_STORAGE, MEAL_COUNT, ITEM_COUNT, createKlaviyoIdentify } from '../../Constants/AppConstants';


class Login extends PureComponent {
    constructor(props) {
        super(props);

        let settings = '';
        if (GET_STORAGE('settings')) {
            settings = JSON.parse(GET_STORAGE('settings'));
        }

        this.state = {
            privacy_policy: settings ? (settings.internal_pages ? settings.internal_pages.privacy_policy : "/") : "/",
            user_login: '',
            password: '',
            reg_email: '',
            reg_password: '',
            role: 'customer',
            remember: '',
            redirect_url: '',
            captchaCode: 'Loading...',
            captcha_input: '',
            // affiliate_code: GET_COOKIE('af'),
            loading: true,
            success_alert_wrapper_show: false,
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true,
            isLoadingR: false,
            server_message: '',
            warning_wrapper_show: true
        }
        document.title = "Login - Prestige Labs";
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    changeRememberHandler = (e) => {
        if (document.getElementById("remember").checked) {
            this.setState({
                remember: 'checked'
            });
        } else {
            this.setState({
                remember: ''
            });
        }
    }

    Captcha = () => {
        let alpha = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
            'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
            '0', '1', '2', '3', '4', '5', '6', '7', '8', '9');
        let i = 0;
        let a = '';
        let b = '';
        let c = '';
        let d = '';
        let e = '';
        let f = '';
        let g = '';
        for (i = 0; i < 6; i++) {
            a = alpha[Math.floor(Math.random() * alpha.length)];
            b = alpha[Math.floor(Math.random() * alpha.length)];
            c = alpha[Math.floor(Math.random() * alpha.length)];
            d = alpha[Math.floor(Math.random() * alpha.length)];
            e = alpha[Math.floor(Math.random() * alpha.length)];
            f = alpha[Math.floor(Math.random() * alpha.length)];
            g = alpha[Math.floor(Math.random() * alpha.length)];
        }
        let code = a + ' ' + b + ' ' + ' ' + c + ' ' + d + ' ' + e + ' ' + f + ' ' + g;
        this.setState({
            captchaCode: code
        });
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        document.body.classList.remove('home_page');
        this.Captcha();
        this.setState({
            loading: false
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const val_return = validateLogin(this.state);
        this.setState(val_return);
        if (val_return.isValid) {
            this.setState({ errors: {}, isLoading: true });
            this.props.userLoginRequest(this.state).then(results => {
                if (results.response.code === 1000) {
                    if (Object.values(results.response.data.roles).includes('customer')) {

                        let settings = null;
                        if (GET_STORAGE("settings")) {
                            settings = JSON.parse(GET_STORAGE("settings"));
                        }

                        let meal_menu_active = false;
                        if (settings && settings.meal_menu_public == "yes") {
                            meal_menu_active = true;
                        } else {
                            if (results.response.data.meal_menu_activated) {
                                meal_menu_active = true;
                            }
                        }

                        createKlaviyoIdentify();

                        if (results.response.data.hasOwnProperty('site')) {
                            if (results.response.data.site == 'refer') {
                                if (meal_menu_active) {
                                    window.location.href = REFER_URL + 'serviceLogin?token=' + results.response.data.token + '&redirect=meals';
                                } else {
                                    window.location.href = REFER_URL + 'serviceLogin?token=' + results.response.data.token;
                                }
                            } else {
                                if (Number(MEAL_COUNT()) + Number(ITEM_COUNT()) > 0) {
                                    history.push('/checkout');
                                } else {
                                    if (meal_menu_active) {
                                        history.push('/meals');
                                    } else {
                                        history.push('/');
                                    }
                                }
                            }
                        } else {
                            if (Number(MEAL_COUNT()) + Number(ITEM_COUNT()) > 0) {
                                history.push('/checkout');
                            } else {
                                if (meal_menu_active) {
                                    history.push('/meals');
                                } else {
                                    history.push('/');
                                }
                            }
                        }

                        // if(history.goBack(1)){
                        //     history.goBack(1);
                        // }else{
                        // history.push('/');
                        // }
                        // }else if(results.response.data.role === 'customer'){
                        //     window.location.href = CUSTOMER_URL+'serviceLogin?token='+results.response.data.token;
                    } else {
                        this.props.logout();
                        REMOVE_STORAGE(USER);
                        this.setState({
                            // server_message: results.response.message,
                            server_message: "Unauthorized Access",
                            isLoading: false,
                            isFormValid: false
                        });
                    }
                } else {
                    this.setState({
                        server_message: results.response.message,
                        isLoading: false,
                        isFormValid: false
                    });
                }
            }
            );
        }
    }

    onSubmitR = (e) => {
        e.preventDefault();
        const val_return = validateSignup(this.state);
        this.setState(val_return);

        if (val_return.isValid) {
            if (this.state.affiliate_code !== '') {
                let data = {
                    email: this.state.reg_email,
                    password: this.state.reg_password,
                    role: this.state.role,
                    affiliate_code: this.state.affiliate_code
                };
                // data.append('email', this.state.reg_email);
                // data.append('password', this.state.reg_password);
                // data.append('role', this.state.role);

                this.setState({ errors: {}, isLoadingR: true });
                this.props.userSignupRequest(data).then(results => {
                    if (results.response.code === 1000) {
                        this.setState({
                            server_message: results.response.message,
                            isLoadingR: false,
                            isFormValid: false,
                            captcha_input: ''
                        });
                        createKlaviyoIdentify();
                        history.push('/checkout');
                    } else {
                        this.setState({
                            server_message: results.response.message,
                            isLoadingR: false,
                            isFormValid: false
                        });
                    }
                }
                );
            } else {
                this.setState({
                    server_message: '<strong>Sorry</strong> You are not allowed to make any purchase on this site. To be able purchase, you must use the referral link from any of our affiliate.',
                    isLoadingR: false,
                    isFormValid: false
                });
            }
        }
    }

    changeUsernameHandler = (event) => {
        this.setState({ user_login: event.target.value }, this.validateInput);
    };

    changeRegnameHandler = (event) => {
        this.setState({ reg_email: event.target.value }, this.validateRegEmailInput);
    };

    validateInput = () => {
        const { user_login } = this.state;
        const pattern = /^[a-zA-Z0-9@._-]*$/;
        const hasError = !pattern.test(user_login) || user_login.length > 50;
        this.setState({
            errors: {
            user_login: hasError
            }
        });
    };
    validateRegEmailInput = () => {
        const { reg_email } = this.state;
        const pattern = /^[a-zA-Z0-9@._-]*$/;
        const hasError = !pattern.test(reg_email) || reg_email.length > 50;
        this.setState({
            errors: {
                reg_email: hasError
            }
        });
    };

    render() {
        const { server_message, success_alert_wrapper_show, errors, isLoading, warning_wrapper_show, captchaCode } = this.state;
        const errors_data = server_message;

        if (this.props.isAuthenticated) {
            history.push('/');
        }

        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <React.Fragment>
                            <div className="login_container contact_container d-flex justify-content-center">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <main className="user_login aff_login_blk">
                                                {/* <h3 className="montserrat page-title">MY ACCOUNT</h3> */}
                                                {
                                                    Number(MEAL_COUNT()) + Number(ITEM_COUNT()) > 0 ?
                                                        <AlertWrapperWarning errors_data="Please log in or register to complete your purchase." warning_wrapper_show={warning_wrapper_show} />
                                                        :
                                                        ''
                                                }
                                                <AlertWrapper errors_data={errors_data} isFormValid={this.state.isFormValid} />
                                                <AlertWrapperSuccess errors_data={errors_data} success_alert_wrapper_show={success_alert_wrapper_show} />
                                                <Tabs
                                                    defaultActiveKey="login"
                                                    id="uncontrolled-tab-example"
                                                    className="login_tabs"
                                                >
                                                    <Tab eventKey="login" title="Login">
                                                        <div className="add_new_card form_input_blk m-0">
                                                            <form className="row" onSubmit={this.onSubmit}>
                                                                <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className={classnames(null, { 'pl_error_label': errors.user_login })} htmlFor="user_login">Username or email address<span className="required">*</span></label>
                                                                    <input type="text" className={classnames("form-control", { 'pl_error_input': errors.user_login })} name="user_login" id="user_login" value={this.state.user_login}  minLength="10" maxLength="50" onChange={this.changeUsernameHandler}/>
                                                                    {errors.user_login && (
                                                                    <small className="error-msg small" style={{ color: 'red', fontSize: '12px', fontFamily: 'Futura, sans-serif' }}>
                                                                        Invalid input: Special characters are not allowed.
                                                                    </small>
                                                                    )}
                                                                </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label className={classnames(null, { 'pl_error_label': errors.password })} htmlFor="password">Password <span className="required">*</span></label>
                                                                        <input className={classnames("form-control", { 'pl_error_input': errors.password })} type="password" name="password" id="password" value={this.state.password} onChange={this.changeHandler} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="w-100">
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <div className="remember_me_label custom-control custom-checkbox">
                                                                                <input onChange={this.changeRememberHandler} type="checkbox" className={classnames('custom-control-input', { 'pl_error_checkbox': errors.remember })} id="remember" name="remember" />
                                                                                <label className="custom-control-label fw_ar_reg m-0" htmlFor="remember">Remember me</label>
                                                                            </div>
                                                                            <NavLink className="font_14 text_black fw_ar_reg text_underline" to="/password-reset"> Lost your password?</NavLink>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 mt_30">
                                                                    <button type="submit" disabled={this.state.isLoading} className="cursor-pointer add_new_btn d-flex font_16 text_white fw_ar_reg w-100" name="login" value="Login">{this.state.isLoading ? 'Please Wait...' : 'Login'}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </Tab>
                                                    <Tab eventKey="Register" title="Register">
                                                        <div className="add_new_card form_input_blk m-0">
                                                            <form className="row" onSubmit={this.onSubmitR}>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label className={classnames(null, { 'pl_error_label': errors.reg_email })} htmlFor="reg_email">Email address <span className="required">*</span></label>
                                                                            <input type="email" className={classnames("form-control", { 'pl_error_input': errors.reg_email })} name="reg_email" id="reg_email" value={this.state.reg_email}  minLength="10" maxLength="50" onChange={this.changeRegnameHandler}/>
                                                                        {errors.reg_email && (
                                                                        <small className="error-msg small" style={{ color: 'red', fontSize: '12px', fontFamily: 'Futura, sans-serif' }}>
                                                                            Invalid input: Special characters are not allowed.
                                                                        </small>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label className={classnames(null, { 'pl_error_label': errors.reg_password })} htmlFor="reg_password">Password <span className="required">*</span></label>
                                                                        <input type="password" className={classnames("form-control", { 'pl_error_input': errors.reg_password })} name="reg_password" id="reg_password" value={this.state.reg_password} onChange={this.changeHandler} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <div className="captcha_container d-flex align-items-center register_recaptcha contact_cppatcha">
                                                                            <h2 type="text" id="mainCaptcha">{captchaCode}</h2>
                                                                            <input type="hidden" id="captchaCode" name="captchaCode" defaultValue={captchaCode} />
                                                                            <input className={classnames("form-control", { 'pl_error_input': errors.captcha_input })} name="captcha_input" id="captcha_input" onChange={this.changeHandler} type="text" value={this.state.captcha_input} />
                                                                            <button type="button" id="refresh" className="shop_more take_quiz_btn font_16 text_white fw_ar_reg" name="login" onClick={this.Captcha}><i className="fa fa-refresh" aria-hidden="true" ></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="w-100">
                                                                        <p className='font_14 text_black fw_ar_reg m-0'>Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our <NavLink to={`${this.state.privacy_policy}`} className="text_black text_underline" target="_blank">privacy policy</NavLink>.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="mt_30 col-md-12">
                                                                    <button disabled={this.state.isLoadingR} type="submit" className="roboto_condensed cursor-pointer add_new_btn d-flex font_16 text_white fw_ar_reg w-100" >{this.state.isLoadingR ? 'Please Wait...' : 'Register'}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </Tab>
                                                </Tabs>
                                            </main>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                }
            </Fragment>
        );
    }
}

Login.propTypes = {
    userLoginRequest: PropTypes.func.isRequired,
    userSignupRequest: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated
    }
}

// export default connect(mapStateToProps, { logout })(Login);

export default connect(mapStateToProps, { userLoginRequest, userSignupRequest, logout })(Login);
