import React, { PureComponent } from 'react';
import { AJAX_REQUEST, SET_STORAGE, GET_STORAGE, BUNDLE_OFFER_NEW } from "../../Constants/AppConstants";
import history from '../../history';
import { connect } from "react-redux";

class Reorder extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            order_id: this.props.match.params.order_id,
            reorder_data: [],
        }
    }

    componentDidMount() {
        let data = {
            order_id: this.state.order_id
        }
        AJAX_REQUEST("POST", "order/getReOrderData", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                let data = results.response.data;
                if (data.supplement) {
                    const hasCustomBundle = data.supplement.some(item => item.is_custom_bundle == 1);
                    if(hasCustomBundle){
                        SET_STORAGE("productDiscount", JSON.stringify(data.product_discount));
                        SET_STORAGE('Bundle', JSON.stringify(data.supplement));
                        BUNDLE_OFFER_NEW()
                        let discount_data_new = (GET_STORAGE("Discount_data")) ? JSON.parse(GET_STORAGE("Discount_data")) : '';
                            const products = JSON.parse(GET_STORAGE("Bundle"));
                            const updatedProducts = products.map((product, index) => {
                                const Inddiscount = discount_data_new[index]; 
                                const newSalePrice = (product.cart_sale_price - Inddiscount);
                                return {
                                    ...product,
                                    custom_bundle_discount_price: newSalePrice.toFixed(2),
                                    custom_bundle_discount: Inddiscount.toFixed(2)
                                };
                            });
                        SET_STORAGE("Bundle", JSON.stringify(updatedProducts));
                        this.props.addToCart();
                    }else{
                        SET_STORAGE('cart', JSON.stringify(data.supplement));
                        this.props.addToCart();
                    }
                }
                if (data.meals) {
                    SET_STORAGE('meals', JSON.stringify(data.meals));
                    this.props.addMealToCart();
                }
                history.push('/cart');
            } else {
                history.push('/my-account/view-order');
            }
        });
    }

    render() {
        return (
            <div className="loading container full_page_loader"></div>
        );
    }
}

const mapDispachToProps = dispach => {
    return {
        addToCart: () => dispach({ type: 'ADD_TO_CART', value: JSON.parse(GET_STORAGE('cart')) }),
        addMealToCart: () => dispach({ type: "ADD_MEAL_TO_CART", value: JSON.parse(GET_STORAGE("meals")) })
    }
};

export default connect(null, mapDispachToProps)(Reorder);